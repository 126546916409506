.month-picker-custom {
  .ant-picker-input {
    display: none !important;
  }
  .ant-picker-outlined {
    border: none;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .weight-700 {
    font-weight: 700 !important;
    border: none;
    box-shadow: none;
  }
}
