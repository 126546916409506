@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('assets/styles/variable.scss');
@import url('assets/styles/form.scss');
@import url('assets/styles/fontFamily.scss');
@import url('assets/styles/table.scss');

body,
html {
  color: var(--text-default-color) !important;
}

* {
  font-family: 'Noto Sans JP', sans-serif !important;
}

.ant-table-container {
  .ant-empty-description {
    font-size: 16px;
  }
}
