:root {
  --text-default-color: #333333;
  --text-form-color: #555555;
  --grey-600: #cccccc;
  --theme-color: #3a3a80;
  --light-grey-color: #777777;
  --grey-400: #aaaaaa;
  --grey-200: #f2f4f6;
  --grey-50: #fafafa;
  --primary-400: #181384;
  --color-unpaid: #ff5050;

  --color-not-confirm: #bbbbbb;
  --color-not-coordinated: #fb8368;
  --color-coordinated: #ffa34e;
  --color-completed: #a3af3e;
  --color-report-received: #5eb5a6;
  --color-pdf-sent: #6d9efd;
  --color-original-sent: #7b79d5;
  --color-sending-complete: #895fe1;

  --color-not-confirm-2: #9c9a9a;
  --color-not-coordinated-2: #faa799;
  --color-coordinated-2: #ffbf83;
  --color-completed-2: #f4f191;
  --color-report-received-2: #97e2b7;
  --color-pdf-sent-2: #97bde2;
  --color-original-sent-2: #9aa3e2;
  --color-sending-complete-2: #de9ae2;
}
