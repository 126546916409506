.date-picker-custom {
  .ant-picker-input {
    display: none !important;
  }
  .ant-picker-outlined {
    border: none;
  }
  .weight-700 {
    font-weight: 700 !important;
    border: none;
    box-shadow: none;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.rbc-time-view {
  .rbc-label {
    display: none;
  }
  .rbc-allday-cell {
    min-height: 300px;
    max-height: unset;
  }
  .rbc-time-content {
    display: none;
  }
}
